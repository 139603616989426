import React from 'react';

const SecondPage = () => (
	<div className="landing">
		<header>
			<h1>Rockoder Ltd</h1>
			<p>Web software solutions</p>
		</header>

		<main>
			<a href="mailto:joao@rockoder.co.uk">Contact</a>
		</main>
	</div>
);

export default SecondPage;
